import React from "react"
import {Link, useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from '../layouts/layout'
import { Helmet } from 'react-helmet'

import Style from './success.module.scss'
import { graphql } from "gatsby";

const Success = () => {
    const {t} = useTranslation();
    return (
        <div>
            <Layout>
                <Helmet>
                    <title>{t("success_title")}</title>
                </Helmet>

                <h1 className={Style.title_h1}>{t("success_h1")}</h1>

                <div className={Style.wrapper}>
                    <Link className={Style.link} to={`/`}>
                        {t("success_tohome")}
                    </Link>
                </div>
            </Layout>
        </div>
    )
}

export default Success

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;